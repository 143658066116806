import { Button as ChakraButton } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import PropTypes from 'prop-types'

export default function Button({
  isActive,
  text,
  variant,
  handleClick,
  value,
  ...others
}) {
  return (
    <ChakraButton
      variant={variant}
      isActive={isActive}
      onClick={() => handleClick(value)}
      leftIcon={variant === 'pill' && isActive && <CheckIcon />}
      {...others}
    >
      {text}
    </ChakraButton>
  )
}

// TODO: SPECIFY PROP TYPE FURTHER => VALUE IS.ANY DEFINE IS.ANY
Button.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.string,
  variant: PropTypes.shape({
    base: PropTypes.string.isRequired,
    md: PropTypes.string,
  }),
}

Button.defaultProps = {
  isActive: false,
  variant: { base: 'pill' },
  value: '',
}
