// eslint-disable-next-line import/prefer-default-export
export const entryButtonOptions = [
  {
    title: 'Buying produce',
    subText:
      '(e.g. CPG companies, distributors, food processors, food service companies, retailers and wholesalers)',
    pathname: '/sign-up/buyer',
    dataCy: 'buyerSignUpButton',
  },
  {
    title: 'Selling produce',
    subText: '(e.g. Large or medium sized farms and produce importers)',
    pathname: '/sign-up/supplier',
    dataCy: 'supplierSignUpButton',
  },
  {
    title: 'Learn more or get involved with Full Harvest',
    subText:
      '(e.g. Potential investors, press and media companies or logistics companies)',
    pathname: '/sign-up/individual',
    dataCy: 'otherSignUpButton',
  },
]
