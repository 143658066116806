import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import {
  Box,
  Spacer,
  Flex,
  VStack,
  Container,
  Heading,
  Text,
} from '@chakra-ui/react'
import { entryButtonOptions } from '@/lib/sign-up/constants'
import Image from 'next/image'
import LogoImage from '@/images/FullHarvest_Logo_Horizontal_RGB.png'
import Button from '@/components/single/Button'

export default function EntrySignUp() {
  const router = useRouter()
  const urlParamsFromRails = router.query

  const redirectSignUp = (pathname) =>
    router.push({ pathname, query: { ...urlParamsFromRails } })

  return (
    <Flex direction="column" height="100%">
      {/* Logo */}
      <VStack
        display="flex"
        pt={{ base: 3, md: 6 }}
        pb={{ base: 3, md: 6 }}
        px={{ base: 2 }}
        width="100%"
        alignItems="center"
        justifyContent="center"
        bg="white"
      >
        <Image src={LogoImage} height={40} width={200} alt="Logo Banner" />
      </VStack>
      <Box>
        <Box
          display="flex"
          bg="gray.200"
          width="100%"
          justifyContent="center"
          alignItems="center"
          py={{ base: 4, md: 6 }}
        >
          <Heading size="md" color="gray.800">
            What best describes your goals?
          </Heading>
        </Box>
        <Container maxW="2xl" pt={10}>
          <VStack>
            {entryButtonOptions.map(
              ({ title, subText, dataCy, pathname }, idx) => (
                <Button
                  key={dataCy}
                  handleClick={() => redirectSignUp(pathname)}
                  variant={{ base: 'secondaryMobile', sm: 'secondary' }}
                  data-cy={dataCy}
                  h="fit-content"
                  py={4}
                  text={
                    <VStack textColor="gray.800">
                      <Text
                        // The last item has slightly different css
                        fontSize={idx === 2 ? 'lg' : '2xl'}
                        fontWeight="bold"
                      >
                        {title}
                      </Text>
                      <Text fontWeight="medium">{subText}</Text>
                    </VStack>
                  }
                />
              ),
            )}
          </VStack>
        </Container>
      </Box>
      <Spacer />
      <Box bg="white" h="6.25rem" />
    </Flex>
  )
}

EntrySignUp.propTypes = {
  urlPathToKeep: PropTypes.shape({
    url_path_to_keep: PropTypes.string,
  }),
}

EntrySignUp.defaultProps = {
  urlPathToKeep: {},
}
