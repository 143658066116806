
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/sign-up/[[...index]]",
      function () {
        return require("private-next-pages/sign-up/[[...index]].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/sign-up/[[...index]]"])
      });
    }
  